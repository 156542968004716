import React, { useEffect, useState } from 'react'
import { SimpleBridgeBuilderForm } from '@features/nbee/SimpleBridgeBuilderForm'
import { useGetBridgeByTemplate } from '@app/api/getBridgeByTemplate'
import { BridgeFormValues } from 'Nbee'
import { transformBridgeFormValuesToApiSaveBridgeBody } from '@app/api/utils/bridge'
import { useCreateNewBridge } from '@app/api/createNewBridge'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { appRoutes, bridgeByUrlPartnersPaths } from '@app/routes'
import { parseApiError } from '@app/api/utils/error'
import { useAppDispatch } from '@app/store/hooks'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { LoadingStep } from '@features/nbee/LoadingStep'
import { BbuRouteParams } from 'LeadsBridgeApp'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { useSetupBridgeById } from '@app/api/postSetupBridge'
import { FormikHelpers } from 'formik'
import { useUpdateBridge } from '@app/api/updateBridge'
import queryString from 'query-string'
import { useGetUserModules } from '@app/api/getUserModules'
import { UserDefault } from 'BackendApi'
import { useGetUserInfo } from '@app/api/getUserInfo'
import { ModalTrialInfo } from '@features/nbee/ModalTrialInfo'
import { useTranslation } from 'react-i18next'

const makeEmptyBridge = (
  sourceAppId?: number,
  destinationAppId?: number,
  emailReceiptDefaults?: UserDefault
): BridgeFormValues => ({
  name: `New bridge`,
  source: {
    appId: sourceAppId,
    integrationId: undefined,
    settings: [],
  },
  destination: {
    appId: destinationAppId,
    integrationId: undefined,
    settings: [],
  },
  settings: {
    emailReceipt: {
      active: Boolean(emailReceiptDefaults?.active ?? false),
      recipients: emailReceiptDefaults?.recipients ?? [],
      subject: emailReceiptDefaults?.subject ?? '',
    },
  },
})

export const Step1: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  const {
    data: user,
    isLoading: isLoadingUser,
    error: userError,
  } = useGetUserInfo()

  const loginParams = queryString.parse(window.location.search)
  const showSurpriseMessage =
    loginParams.currentPlan === 'freemium' ||
    loginParams?.currentPlan?.includes('starter')
  const lbfsgn = loginParams.lbfsgn === 'true'

  const planName =
    loginParams.currentPlan === 'freemium'
      ? 'Free'
      : loginParams?.currentPlan?.includes('starter')
      ? 'Starter'
      : ''

  const isFirstLogin = user?.newUser || lbfsgn

  useEffect(() => {
    if (isFirstLogin) handleOpenModal()
  }, [isFirstLogin])

  const [bridgeFormikProps, setBridgeFormikProps] = useState<{
    formValues: BridgeFormValues
    formikHelpers: FormikHelpers<BridgeFormValues>
  } | null>(null)

  // if is bbu uri: `/bc/(app|lb)/facebook-lead-ads/google-sheets`
  const bbuMatchUrl = matchPath<BbuRouteParams>(location.pathname, {
    path: bridgeByUrlPartnersPaths,
    exact: true,
  })

  const bbuUri = bbuMatchUrl
    ? `${window.location.origin}${bbuMatchUrl.url}`
    : undefined

  const { data: bridgeTemplateValues, isLoading: isLoadingBridgeTemplate } =
    useGetBridgeByTemplate(bbuUri)

  const urlParsed = queryString.parse(location.search, {
    parseNumbers: true,
  })
  const sourceAppIdFromQueryString =
    urlParsed && urlParsed.sourceId
      ? parseInt(`${urlParsed.sourceId}`, 10)
      : undefined
  const destinationAppIdFromQueryString =
    urlParsed && urlParsed.destinationId
      ? parseInt(`${urlParsed.destinationId}`, 10)
      : undefined

  const {
    data: userModulesResponse,
    isLoading: isLoadingUserModules,
    error: apiUserModulesError,
  } = useGetUserModules()
  const userModules = userModulesResponse?.modules || []
  const emailReceiptUserModule = userModules.find(
    (m) => m.id === 'email-receipt'
  )

  // prepare initial values base on BBU data or empty bridge
  // in case we have sourceId or destinationId in query string we prefill them
  const initialValues =
    bbuUri && bridgeTemplateValues
      ? bridgeTemplateValues
      : makeEmptyBridge(
          sourceAppIdFromQueryString,
          destinationAppIdFromQueryString,
          emailReceiptUserModule?.userDefaults
        )

  // Save bridge
  const {
    mutate: createNewBridge,
    data: createNewBridgeResponse,
    error: createNewBridgeError,
    isLoading: isCreatingNewBridge,
  } = useCreateNewBridge()

  // Update bridge
  const {
    mutate: updateBridge,
    data: updateBridgeResponse,
    error: updateBridgeError,
    isLoading: isUpdatingBridge,
  } = useUpdateBridge()

  // Setup bridge
  const {
    mutate: setupBridge,
    data: bridgeSetupResponse,
    error: bridgeSetupApiError,
    isLoading: isSettingUpBridge,
  } = useSetupBridgeById()

  const isSavingApi =
    isCreatingNewBridge || isSettingUpBridge || isUpdatingBridge
  const apiError =
    createNewBridgeError || bridgeSetupApiError || updateBridgeError
  const createdBridgeId = createNewBridgeResponse?.data?.bridge?.id // when is the first time user saves the bridge
  const updatedBridgeId = updateBridgeResponse?.data?.bridge?.id // in case setup bridge fails, the second save bridge attemps needs to be done as update since we already have a bridge id

  // when createNewBridgeResponse returns success we need to call the bridge setup endpoint
  useEffect(() => {
    const bridgeId = createdBridgeId || updatedBridgeId
    if (bridgeId) {
      setupBridge({ bridgeId })
    }
  }, [createdBridgeId, updatedBridgeId])

  // when setup bridge is completed we can redirect the user to next step
  useEffect(() => {
    if (bridgeSetupResponse && createdBridgeId) {
      history.push(appRoutes.nbeeStep2.makeUrl(createdBridgeId.toString()))
    }
  }, [bridgeSetupResponse])

  // in case of error we notify the user
  useEffect(() => {
    if (apiError) {
      const parsedError = parseApiError(apiError)
      dispatch(
        sendToast({
          title: 'Error',
          message: parsedError.message,
          color: 'negative',
        })
      )

      if (bridgeFormikProps) {
        bridgeFormikProps.formikHelpers.setSubmitting(false)

        trackEvent({
          eventName: 'UnexpectedErrorThrown',
          feature: 'NBEE',
          step: 'Apps',
          params: {
            ...makeNbeeTrackingParams(bridgeFormikProps.formValues),
            custom: {
              errorCode: parsedError.code,
              errorDescription: parsedError.message,
            },
          },
          sendEventToIntercom: true,
        })
      }
    }
  }, [apiError])

  return isLoadingBridgeTemplate || isLoadingUserModules ? (
    <LoadingStep activeStepIndex={0} loadingText={t('nbee.bridgeBuilder.step1TemplateLoadingMessage')} />
  ) : (
    <>
      <ModalTrialInfo
        isOpen={isModalOpen}
        onCloseModal={handleCloseModal}
        showSurpriseMessage={showSurpriseMessage}
        planName={planName}
      />
      <SimpleBridgeBuilderForm
        initialValues={initialValues}
        isSavingApi={isSavingApi}
        emailReceiptUserModule={emailReceiptUserModule}
        userData={user}
        onSubmit={(formValues, formikHelpers) => {
          const newBridgeData = transformBridgeFormValuesToApiSaveBridgeBody(
            formValues,
            { step: 1 }
          )
          // we store all formik handlers in order to access them in the component scope
          setBridgeFormikProps({ formValues, formikHelpers })

          // The first time we call the create (save) new bridge endpoint and in case of success the setup bridge will be called right after (check useEffect -> setupBridge)
          // If setup bridge fails, the user will need to edit the bridge configuration and the second save attemp needs to be done by calling the update bridge endpoint
          if (!createdBridgeId) {
            createNewBridge(newBridgeData)
          } else {
            updateBridge({
              bridgeData: newBridgeData,
              bridgeId: `${createdBridgeId}`,
            })
          }
        }}
      />
    </>
  )
}
