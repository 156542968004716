import React from 'react'

export const TestComponents: React.FC = () => {
  // Check if the current hostname matches the production domain
  const isProduction = window.location.hostname === 'app.leadsbridge.com'

  if (isProduction) {
    // Prevent this page from appearing in production :)
    return null
  }

  return (
    <div>
      <iframe
        width='1200'
        height='800'
        src='https://stage-app.leadsbridge.com/bc/tt/tiktok-lead-generation/zoho-crm'
        title='leadsbridge'
      />
    </div>
  )
}
