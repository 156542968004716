import React, { useEffect, useState } from 'react'
import { FieldsMappingForm } from '@features/nbee/FieldsMappingForm'
import { LoadingStep } from '@features/nbee/LoadingStep'
import { transformBridgeFormValuesToApiSaveBridgeBody } from '@app/api/utils/bridge'
import { NbeeError } from '@features/nbee/NbeeError'
import { parseApiError } from '@app/api/utils/error'
import { useGetBridgeById } from '@app/api/getBridgeById'
import { useUpdateBridge } from '@app/api/updateBridge'
import { useHistory, useParams } from 'react-router-dom'
import { useGetBridgeAllFields } from '@app/api/getBridgeAllFields'
import {
  makeFieldsMappingInitialValuesAndListNotMatchingFields,
  FieldMappingMatched,
  useNotifyUserOfMissingMappedFields,
} from '@features/nbee/FieldsMappingForm/initialStateUtils'
import { useAppDispatch } from '@app/store/hooks'
import { sendAlertMessage } from '@app/store/actions/ApplicationConfigurationActions'
import { appRoutes } from '@app/routes'
import { NbeeStepCompletedState } from '@app/pages/Nbee/Step3'
import { usePublishBridge } from '@app/api/publishBridge'
import { FormikHelpers } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { Button } from '@components/Basic/Button'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { useGetUserInfo } from '@app/api/getUserInfo'
import { useGetBridgeAvailablePermissionChecks } from '@app/api/getBridgeAvailablePermissionChecks'
import { useRunBridgePermissionChecks } from '@app/api/postRunBridgePermissionChecks'
import { ApiErrorResponse } from 'BackendApi'
import { calculateInitialShouldCheckPermissionsState } from '@app/features/nbee/utils'

export const Step2: React.FC = () => {
  const params: { bridgeId: string } = useParams()
  const bridgeId = parseInt(params.bridgeId, 10)

  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  // Each of these hooks handles the fetching, loading state, and error state for each respective API call
  const {
    data: user,
    isLoading: isLoadingUser,
    error: userError,
  } = useGetUserInfo()

  const {
    data: bridgeFormValues,
    error: errorGettingBridge,
    refetch: onRefetchBridgeFormValues,
    isLoading: isLoadingBridge,
    isRefetching: isReloadingBridge,
  } = useGetBridgeById(`${bridgeId}`, true)

  const {
    data: availablePermissionChecks,
    error: errorGettingAvailablePermissionChecks,
    isLoading: isLoadingAvailablePermissionChecks,
  } = useGetBridgeAvailablePermissionChecks(bridgeId)

  const {
    data: allBridgeFields,
    error: allBridgeFieldsError,
    isLoading: loadingAllBridgeFields,
    isRefetching: isReloadingAllBridgeFields,
  } = useGetBridgeAllFields(bridgeId)

  // Each of these hooks handles the mutation, loading state, and error state for each respective API call
  const {
    mutate: updateBridge,
    error: updateBridgeError,
    isLoading: isUpdatingBridge,
  } = useUpdateBridge()

  const {
    mutate: publishBridge,
    data: publishBridgeResponse,
    error: publishBridgeApiError,
    isLoading: isPublishingBridge,
  } = usePublishBridge()

  const {
    mutate: runPermissionChecks,
    error: errorRunningPermissionChecks,
    isLoading: isRunningPermissionChecks,
  } = useRunBridgePermissionChecks()

  // prepare data for UI
  // we are not checking `isReloadingAllBridgeFields` here because we want to leave that loading state internal to the form
  // in this way non saved mapping (form state) will not be lost
  const isLoadingAll =
    isLoadingBridge ||
    isReloadingBridge ||
    loadingAllBridgeFields ||
    isLoadingAvailablePermissionChecks ||
    isRunningPermissionChecks

  const apiError =
    errorGettingBridge ||
    allBridgeFieldsError ||
    errorGettingAvailablePermissionChecks

  const allDataReady = Boolean(
    bridgeFormValues && allBridgeFields && availablePermissionChecks
  )

  const showWelcomeMessage =
    !!allBridgeFields?.data?.requiresTemplateBasedFieldsMapping

  // we need to know if we should perform permission checks at form submission or not
  const [shouldCheckPermissions, setShouldCheckPermissions] = useState<boolean>(
    availablePermissionChecks?.shouldCheckPermissions || false
  )

  // we want to ensure that the data is ready before we calculate the initial state of shouldCheckPermissions
  useEffect(() => {
    setShouldCheckPermissions(
      calculateInitialShouldCheckPermissionsState(
        availablePermissionChecks || undefined
      )
    )
  }, [availablePermissionChecks])

  // every time we receive new bridgeFormValues or new allBridgeFields we create a new fieldMappingMatched state
  const [fieldsMappingMatched, setFieldsMappingMatched] =
    useState<FieldMappingMatched | null>(null)

  useEffect(() => {
    // we also need to be sure that when we have data ready there is not refetching in action or we will generate
    // a new fieldMappingMatched state with non fresh data
    if (
      bridgeFormValues &&
      allBridgeFields &&
      availablePermissionChecks &&
      !isReloadingBridge &&
      !isReloadingAllBridgeFields
    ) {
      setFieldsMappingMatched(
        makeFieldsMappingInitialValuesAndListNotMatchingFields(
          bridgeFormValues,
          allBridgeFields
        )
      )
    }
  }, [
    availablePermissionChecks,
    bridgeFormValues,
    allBridgeFields,
    isReloadingBridge,
    isReloadingAllBridgeFields,
  ])

  // we want to pass down to FieldsMappingForm via props both the existing filter rules and the form initial values
  const bridgeFilterRules = bridgeFormValues?.sourceFilters?.conditions || []

  const initialValues = fieldsMappingMatched?.initialValues

  // we notify users by displaying an alert message if `fieldsMappingMatched` contains not found fields
  useNotifyUserOfMissingMappedFields(fieldsMappingMatched, bridgeId)

  const handleApiError = (
    error: ApiErrorResponse,
    formikValues: BridgeFormValues,
    formikHelpers: FormikHelpers<BridgeFormValues>
  ) => {
    formikHelpers.setSubmitting(false)
    const parsedError = parseApiError(error)
    trackEvent({
      eventName: 'UnexpectedErrorThrown',
      feature: 'NBEE',
      step: 'Apps',
      params: {
        ...makeNbeeTrackingParams(formikValues),
        custom: {
          errorCode: parsedError.code,
          errorDescription: parsedError.message,
        },
      },
      sendEventToIntercom: true,
    })
  }

  // whenever an error occurs, we want to notify the user on both update bridge, publish bridge and check permissions actions
  useEffect(() => {
    if (
      updateBridgeError ||
      publishBridgeApiError ||
      errorRunningPermissionChecks
    ) {
      const error =
        updateBridgeError ||
        publishBridgeApiError ||
        errorRunningPermissionChecks
      const errorMessage = parseApiError(error!)

      if (errorMessage.code === 'bridge-limit-reached' && bridgeFormValues) {
        trackEvent({
          eventName: 'UpgradeButtonShown',
          step: 'Apps',
          feature: 'NBEE',
          params: {
            ...makeNbeeTrackingParams(bridgeFormValues),
          },
        })
      }

      dispatch(
        sendAlertMessage({
          isDismissable: true,
          message: errorMessage.message,
          useTranslation: true,
          persistent: true,
          buttons:
            errorMessage.code === 'bridge-limit-reached' ? (
              <a
                href={
                  window.location.origin +
                  appRoutes.pricing.makeUrl() +
                  '?reason=bridge-exceeded'
                }
                target='_blank'
                rel='noreferrer'
              >
                <Button $variant={'primary'}>
                  {t('nbee.checkCompatibility.updatePlanCta')}
                </Button>
              </a>
            ) : undefined,
        })
      )
    }
  }, [updateBridgeError, publishBridgeApiError, errorRunningPermissionChecks])

  // once bridge is updated we can publish it  we need to setSubmitting(false)
  // not only on error but also when success but result is false.
  // useEffect below will handle the error displaying or the redirection in case of success
  const handlePublishBridge = (
    formikValues: BridgeFormValues,
    formikHelpers: FormikHelpers<BridgeFormValues>
  ) => {
    publishBridge(
      { bridgeId },
      {
        onError: (apiError) => {
          formikHelpers.setSubmitting(false)
          const parsedError = parseApiError(apiError)
          trackEvent({
            eventName: 'UnexpectedErrorThrown',
            feature: 'NBEE',
            step: 'Apps',
            params: {
              ...makeNbeeTrackingParams(formikValues),
              custom: {
                errorCode: parsedError.code,
                errorDescription: parsedError.message,
              },
            },
            sendEventToIntercom: true,
          })
        },
        onSuccess: (response) => {
          if (response.data?.result === false) {
            formikHelpers.setSubmitting(false)
          } else {
            // track event on real publish success
            trackEvent({
              eventName: 'BridgePublished',
              feature: 'NBEE',
              step: 'Publish',
              params: {
                ...makeNbeeTrackingParams(formikValues),
              },
              sendEventToIntercom: Boolean(formikValues?.source.appId === 370), // only send to intercom if source is Facebook Lead Ads
            })
          }
        },
      }
    )
  }

  const handleUpdateBridgeSuccess = (
    formikValues: BridgeFormValues,
    formikHelpers: FormikHelpers<BridgeFormValues>
  ) => {
    if (shouldCheckPermissions) {
      runPermissionChecks(
        {
          bridgeId,
          permissionChecklist:
            availablePermissionChecks?.data.permissionChecklist || [],
        },
        {
          // both onSuccess and onError, we want to not process the permission checks anymore during this session
          onSuccess: () => {
            // if all permission checks are ok, we can publish the bridge
            setShouldCheckPermissions(false)
            handlePublishBridge(formikValues, formikHelpers)
          },
          onError: (error) => {
            // if one of the permission checks fails, we want to display the error to the user and stop the flow once
            setShouldCheckPermissions(false)
            handleApiError(error, formikValues, formikHelpers)
          },
        }
      )
    } else {
      handlePublishBridge(formikValues, formikHelpers)
    }
  }

  // when bridge is published we check the status and is 200
  const publishedBridgeReponse = publishBridgeResponse?.data
  const isUserPro = user?.pricing.cbPlanId.split('_')[0] === 'pro'

  useEffect(() => {
    if (publishedBridgeReponse && publishedBridgeReponse.result) {
      // bridge has been published, we can redirect the user
      const firstBridge = publishedBridgeReponse.firstBridge && isUserPro
      const step3State: NbeeStepCompletedState = { success: true, firstBridge }
      history.push(appRoutes.nbeeStep3.makeUrl(bridgeId.toString()), step3State)
    }
  }, [publishedBridgeReponse])

  return apiError ? (
    <NbeeError
      activeStepIndex={1}
      bridgeId={bridgeId}
      errorCode={parseApiError(apiError).code}
      statusCode={parseApiError(apiError).status}
      errorMessage={parseApiError(apiError).message}
    />
  ) : isLoadingAll || !fieldsMappingMatched ? (
    <LoadingStep
      activeStepIndex={1}
      bridgeId={bridgeId}
      loadingText={
        isRunningPermissionChecks
          ? t('nbee.bridgeBuilder.permissionCheckLoadingMessage')
          : t('nbee.bridgeBuilder.step2LoadingMessage')
      }
    />
  ) : allDataReady && initialValues && allBridgeFields ? (
    <FieldsMappingForm
      showWelcomeMessage={showWelcomeMessage}
      bridgeId={bridgeId}
      key={`${initialValues.source.appId}-${initialValues.destination.appId}`}
      isSavingApi={isUpdatingBridge || isPublishingBridge}
      initialValues={initialValues}
      allBridgeFields={allBridgeFields.data}
      bridgeFilterRulesList={bridgeFilterRules}
      onRefetchBridgeFormValues={onRefetchBridgeFormValues}
      availablePermissionChecks={availablePermissionChecks}
      onSubmit={(formValues, formikHelpers) => {
        const updateBridgeData = transformBridgeFormValuesToApiSaveBridgeBody(
          formValues,
          { step: 2 }
        )
        updateBridge(
          {
            bridgeData: updateBridgeData,
            bridgeId: `${bridgeId}`,
          },
          {
            onSuccess: () =>
              handleUpdateBridgeSuccess(formValues, formikHelpers),
            onError: (error) =>
              handleApiError(error, formValues, formikHelpers),
          }
        )
      }}
    />
  ) : null
}
